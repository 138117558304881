<template>

  <div class="shadow-2xl rounded-3xl w-full mx-auto">
    <div class="intro-y box col-span-2">


      <div class="container " style="margin: 0 1rem; padding: 0 1rem; border: 1px solid #d8d6de; border-radius: 0.357rem;">
        <p>Select Date range </p>
        
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="fromDate">From:</label>
          <input required v-model="from_date" type="date" class="form-control" id="fromDate">
        </div>
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="toDate">To:</label>
          <input required v-model="to_date" type="date" class="form-control" id="toDate">
        </div>
        
        
        <button @click.prevent="SearchinvoiceListData" class="btn btn-primary"  style=" margin-right: 1rem; border-color: #00cfe8 !important; background-color: #00cfe8 !important;">Submit</button>
        
      </div>
      <h4 >Total Users : {{total_user}}   Total Bill : {{ total }}</h4>
      <table class="w-full border border-gray-300">
        <thead>
          <tr>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">SL</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">User Name</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Status</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invoice, index) in invoices" :key="index" :style="{
                'background-color': invoice.status === 1 ? '#A3E4D7' : '#F1948A'
              }" >
            <td class="pl-2 border-b border-l border-r border-gray-300">{{ index+1 }}</td>
            <td class="pl-2 border-b border-l border-r border-gray-300 ">
              {{invoice.user_name}}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300">
              {{ invoice.status === 1 ? 'Active' : 'Inactive' }}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300 ">
              {{invoice.billing_price}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
BAvatar,
BPagination,
BFormGroup,
BFormInput,
BFormSelect,
BRow,
BCol,
BLink,
BTooltip,
BMedia,
BBadge,
BButton,
VBToggle,
BSidebar,
BForm,
BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";


import { ISP_LIST, DATE_WISE_REPORT,DATE_WISE_REPORT_NEW,UN_INVOICED_REPORT, TEST} from "@core/services/api";
  export default {
    mixins: [Mixin],
directives: {
  Ripple,
  "b-toggle": VBToggle,
},
components: {
  BCardCode,
  VueGoodTable,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  BSidebar,
  BForm,
  BFormTextarea,
  ValidationProvider,
  ValidationObserver,
  vSelect,
  flatPickr,
  // eslint-disable-next-line vue/no-unused-components
  ToastificationContent,
},
data() {
  
      return {
        isp_id:JSON.parse(localStorage.getItem('userData')),
        name: null,
        price: null,
        total : 0,
        total_user : 0,
        invoices: [],
        test: [],
        isp_id:JSON.parse(localStorage.getItem('userData')),
        from_date :"",
        to_date :"",
        
      }
    },
    methods: {
      SearchinvoiceListData1() {
      this.$http
          .get(process.env.VUE_APP_BASEURL + UN_INVOICED_REPORT, {
          params: {
            from_date: this.from_date,
            to_date: this.to_date,
            isp_id: this.isp_id.id,
            
          },
        })
          .then((res) => {
          console.log('Invoice data:',res.data);
          console.log('hi');
          this.invoices =res.data.data.invoices;
          // this.test = res.data.data;
          })
          .catch((error) => {
          if (error.response.status === 404) {
              userData.value = undefined;
          }
          });
      },
      SearchinvoiceListData() {
          this.$http
              .get(process.env.VUE_APP_BASEURL + UN_INVOICED_REPORT, {
              params: {
                from_date: this.from_date,
                to_date: this.to_date,
                isp_id: this.isp_id.id,
                
              },
            })
              .then((res) => {
              console.log('Invoice data:',res.data);
              console.log('hi');
              this.invoices =res.data.data.invoices;
              this.total =res.data.data.total;
              this.total_user =res.data.data.total_user;
              // console.log('Invoice data:',res.data.data.invoices);
              })
              .catch((error) => {
              if (error.response.status === 404) {
                  userData.value = undefined;
              }
              });
          },
          
    },

    mounted() {
     

    },
  }
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";


</style>
  <style scoped>
  .error-message {
    color: red;
  }
  </style>